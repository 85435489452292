<template>
    <div class="global-content">
        <van-collapse v-model="activeName" accordion>
            <van-collapse-item title="筛选条件" class="filter">
                <van-cell title="按设备名称查" :value="filter.dev.value" is-link @click="filter.dev.showPicker = true"/>
                <van-cell title="按识别状态查" :value="filter.way.value" is-link @click="filter.way.show = true"/>
                <van-cell title="选择开始时间" :value="filter.time.startVal" is-link @click="filter.time.startShow = true"/>
                <van-cell title="选择结束时间" :value="filter.time.endVal" is-link @click="filter.time.endShow = true"/>
                <van-row class="text-center">
                    <van-col span="12"><van-button color="#23b593" size="small" @click="resetData">重置</van-button></van-col>
                    <van-col span="12"> <van-button color="#23b593" size="small" @click="filterData">筛选</van-button></van-col>
                </van-row>
            </van-collapse-item>
        </van-collapse>
        <div class="inner">
             <div v-if="!emptyData">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" :immediate-check="false">
                    <div class="block" v-for="(item,index) in dataList" :key="index">
                        <div class="flex bottom-line">
                            <div class="thumb" @click="previewImg(item.picUrl)">
                                <van-image
                                    radius="5"
                                    class="img-thumb"
                                    fit="cover"
                                    :src="item.picUrl"
                                />
                            </div>
                            <div class="info-title">
                                <div>名称：{{item.devName || '暂无'}}</div>
                                <div>编号：{{item.devCode}}</div>
                            </div>
                        </div>
                        <div>
                            <van-row class="info">
                                <van-col span="12">状态：{{item.resultRevise === '0' ? '干燥' : item.resultRevise === '1' ? '覆水' :item.resultRevise === '2' ? '结冰' : item.resultRevise === '3'? '积雪' :'未检测到'}}</van-col>
                                <van-col span="12">置信度：{{item.cf}}</van-col>
                            </van-row>
                            <van-row class="info">
                                <van-col span="12">温度：{{item.airTemp !== null ? item.airTemp.toFixed(1) : '-'}} ℃</van-col>
                                <van-col span="12">湿度：{{item.airHumi !== null ? item.airHumi : '-'}} %</van-col>
                            </van-row>
                            <van-row class="info">
                                <van-col span="12">风向：{{ toFormatWindDir(item.windDirection)}} </van-col>
                                <van-col span="12">
                                    <span v-if="item.windSpeed === -1 || item.windSpeed === null">
                                        风速：- -
                                    </span>
                                    <span v-else>
                                        风速：{{item.windSpeed !== null ? item.windSpeed.toFixed(1) : '-'}} m/s
                                    </span>
                                </van-col>
                            </van-row>
                            <van-row class="info">
                                <van-col span="12">大气压：{{item.atmoPressure !== null ? (item.atmoPressure / 100).toFixed(1) : '-'}} hPa</van-col>
                            </van-row>
                            
                        </div>
                        <div class="time top-line">检测位置：{{item.locFlag || '未设置'}}</div>
                        <div class="time">更新时间：{{item.uploadTime || '0000-00-00 00:00:00'}}</div>
                    </div>
                </van-list>
             </div>
            <div v-else><van-empty description="没有更多数据了" /></div>
        </div>
        <!-- 设备编号 -->
        <van-popup v-model="filter.dev.showPicker" round position="bottom">
            <van-picker show-toolbar :columns="filter.dev.columns" @cancel="filter.dev.showPicker = false" @confirm="devConfirm" />
        </van-popup>
         <!-- 识别状态-->
        <van-action-sheet v-model="filter.way.show" :actions="filter.way.actions" @select="waySelect" />
        <!-- 筛选时间段 -->
        <van-popup v-model="filter.time.startShow" round position="bottom">
        <van-datetime-picker
            v-model="filter.time.start"
            type="datetime"
            title="选择开始时间"
            @confirm="confromStartTime"
            @cancel="filter.time.startShow = false"
        />
        </van-popup>
        <van-popup v-model="filter.time.endShow" round position="bottom">
        <van-datetime-picker
            v-model="filter.time.end"
            type="datetime"
            title="选择结束时间"
            @confirm="confromEndTime"
            @cancel="filter.time.endShow = false"
        />
        </van-popup>
        <tabBar />
    </div>
</template>

<script>
import tabBar from '@/components/jcw/tabBar.vue'
import moment from "moment";
import { ImagePreview } from 'vant';

export default {
    name:'Warn',
    components: {
        tabBar
    },
    data(){
        return{
            emptyData:false,
            loading:false,
            finished:false,
            pageNum:1,
            pageSize:10,
            dataList:[],
            activeName: '',
            filter:{
                dev:{
                    devCode:'',
                    value:'',
                    showPicker:false,
                    columns:[]
                },
                time:{
                    start:new Date(),
                    end:new Date(),
                    startVal:'',
                    endVal:'',
                    startShow:false,
                    endShow:false,
                },
                way:{
                    code:'',
                    value:'',
                    show:false,
                    actions:[
                        {name:'干燥',id:0},
                        {name:'覆水',id:1},
                        {name:'结冰',id:2},
                        {name:'积雪',id:3},
                        {name:'未检测到',id:-1},
                    ]
                },
            },
        }
    },
    mounted(){
        this.projectCode = window.sessionStorage.getItem('projectCode');
        this.getDataList();
        this.getDevInfo();
        setTimeout(()=>{
            this.loading = false;
        },1000)
    },
    methods:{
        // 筛选告警类型
        waySelect(v){
            this.filter.way.show = false;
            this.filter.way.value = v.name;
            this.filter.way.code = v.id;
        },
        toFormatWindDir(v){
            let text = '';
            v == 1 ? text='北风' :
            v == 2 ? text='东北风' :
            v == 3 ? text='东风' :
            v == 4 ? text='东南风' :
            v == 5 ? text='南风' :
            v == 6 ? text='西南风' :
            v == 7 ? text='西风': 
            v == 8 ? text='西北风': text='- -' ;
            return text;
        },
        // 确认开始时间
        confromStartTime(value){
        this.filter.time.startVal = moment(value).format("yyyy-MM-DD HH:mm:ss");
        this.filter.time.startShow = false;
        },
        // 确认结束时间
        confromEndTime(value){
        this.filter.time.endVal = moment(value).format("yyyy-MM-DD HH:mm:ss");
        this.filter.time.endShow = false;
        },
        getDataList(){
            const data = {
                devCode:this.filter.dev.devCode ? this.filter.dev.devCode : void 0,
                beginTime:this.filter.time.startVal || void 0,
                endTime:this.filter.time.endVal || void 0,
                projectCode:this.projectCode,
                resultRevise:this.filter.way.code !== null && this.filter.way.code !== '' ? this.filter.way.code : void 0
            }
            this.$api.JCW.historyInfoPage(this.pageNum,this.pageSize,data).then( d =>{
                if(Array.isArray(d)){
                    this.loading = false;
                    this.dataList = this.dataList.concat(d);
                    this.pageSize > d.length ? this.finished = true : void 0;
                    this.pageNum ++;
                }
            })
        },
        // 获取设备信息
        getDevInfo(){
            this.$api.JCW.deviceInfoList({
                projectCode:this.projectCode
            }).then( d => {
                d.forEach(element => {
                    element.text = element.devName
                });
                this.filter.dev.columns = d
            })
        },
        // 重置
        resetData(){
            this.filter.dev.value = '';
            this.filter.dev.devCode = '';
            this.filter.time.startVal = '';
            this.filter.time.endVal = '';
            this.filter.way.code = '';
            this.filter.way.value='';
        },
        // 筛选
        filterData(){
            this.loading = true;
            this.finished = false;
            this.activeName = '';
            this.dataList = [];
            this.pageNum = 1;
            this.getDataList();
        },
        // 筛选设备编号确认事件
        devConfirm(v){
            this.filter.dev.showPicker = false;
            this.filter.dev.value = v.text;
            this.filter.dev.devCode = v.devCode;
        },
        // 筛选数据类型确认事件
        dataTypeConfirm(v){
            this.loading = true;
            this.dataType.code = v.val;
            this.dataType.value = v.text;
            this.dataType.showPicker = false;
            this.dataList = [];
            this.pageNum = 1;
            this.getDataList();
        },
        // 筛选时间确认事件
        timeConfirm(date) {
            const [start, end] = date;
            this.filter.time.show = false;
            this.filter.time.start = moment(start).format("yyyy-MM-DD");
            this.filter.time.end = moment(end).format("yyyy-MM-DD");
            this.filter.time.value = `${this.filter.time.start} 至 ${this.filter.time.end}`;
        },
        // 预览图片
        previewImg(url){
            ImagePreview([url]);
        }
    }
}
</script>
<style lang="scss" scoped>
.global-content{
    text-align: left;
    .text-center{
        text-align: center;
        button{
            padding: 0 20px;
            margin-top: 5%;
        }
    }
    .van-cell__value{
        text-align: right;
    }
}
.van-empty{margin: 25% 0;}
.inner{
    text-align: left;
    padding-bottom: 22%;
    .block{
        padding: 4%;
        background-color: #9ae6e285;
        font-size: .8rem;
        border: 1px solid #a8f3ef;
        border-radius: 10px;
        margin: 2.5%;
        .bottom-line{padding-bottom: 2%;border-bottom: 1px solid #fff;}
        .top-line{padding-top: 2%;border-top: 1px solid #fff;}
        .flex{
            display: flex;
            // justify-content:space-between;
            margin: 2% 0;
            font-weight: bold;
            .thumb{
                width: 15%;
                .img-thumb{
                    width: 55px;
                    height: 55px;
                    // width: 100%;
                    // border-radius: 10px;
                }
            }
            .info-title{
                width: 85%;
                box-sizing: border-box;
                padding-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }
        }
        .info div{margin: 2% 0;color: #008a7d;}
        .time{color: #656565;margin-top:2%;}
    }
}
</style>